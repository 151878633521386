import {graphql, useStaticQuery} from 'gatsby'
import Container from './container'
import Fade from 'react-reveal/Fade'
import PageHeading from './page-heading'
import ProjectThumbnail from './project-thumbnail'
import React from 'react'
import styled from 'styled-components'
import SubHeading from './sub-heading'
import TextBlock from './text-block'

export default function ProjectsSection({cssModifier}) {
  const data = useStaticQuery(graphql`
    {
      prismicProjectsPage {
        data {
          page_heading {
            text
          }
          intro_text {
            html
          }
        }
      }
      allPrismicProject {
        edges {
          node {
            data {
              enabled
              is_completed
              project_date
              project_name {
                text
              }
              project_description {
                html
              }
              location {
                text
              }
              primary_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              featured_project
            }
            prismicId
            uid
          }
        }
      }
    }
  `)

  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()

  const prismicData = {
    pageHeading: data.prismicProjectsPage.data.page_heading.text,
    introText: data.prismicProjectsPage.data.intro_text.html,
    projects: data.allPrismicProject.edges.map(edge => {
      let dateObjToUse
      if (edge.node.data.project_date) {
        dateObjToUse = new Date(edge.node.data.project_date)
      } else {
        dateObjToUse = currentDate
      }
      return {
        date: dateObjToUse,
        description: edge.node.data.project_description.html,
        location: edge.node.data.location.text,
        enabled: edge.node.data.enabled,
        isCompleted: edge.node.data.is_completed,
        featuredProject: edge.node.data.featured_project,
        name: edge.node.data.project_name.text,
        primaryImageAlt: edge.node.data.primary_image.alt,
        primaryImageHeight: edge.node.data.primary_image.dimensions?.height,
        primaryImageUrl: edge.node.data.primary_image.url,
        primaryImageWidth: edge.node.data.primary_image.dimensions?.width,
        prismicId: edge.node.prismicId,
        slug: edge.node.uid,
        year: edge.node.data.project_date?.split('-')[0] || currentYear,
      }
    }),
  }
  prismicData.projects = prismicData.projects.filter(item => item.enabled)
  prismicData.projects.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date)
  })

  const featuredProjects = prismicData.projects.filter(project => project.featuredProject)
  const completedProjects = prismicData.projects.filter(project => !project.featuredProject && project.isCompleted)
  const inProgressProjects = prismicData.projects.filter(project => !project.featuredProject && !project.isCompleted)
  const allProjects = [...featuredProjects, ...completedProjects, ...inProgressProjects]

  return (
    <StyledComponent className={`site-section ${cssModifier ? `site-section--${cssModifier}` : ''}`}>
      <PageHeading heading={prismicData.pageHeading} />
      <Container type="wide">
        <div className="row">
          {prismicData.introText && (
            <div className="intro-text-wrapper">
              <TextBlock cssModifier="intro-text">
                <div dangerouslySetInnerHTML={{__html: prismicData.introText}} />
              </TextBlock>
            </div>
          )}

          {allProjects.length > 0 && (
            <>
              <ol className="projects-list">
                {allProjects.map(project => (
                  <ProjectThumbnail
                    key={project.prismicId}
                    name={project.name}
                    location={project.location}
                    isCompleted={project.isCompleted}
                    primaryImageAlt={project.primaryImageAlt}
                    primaryImageHeight={project.primaryImageHeight}
                    primaryImageUrl={project.primaryImageUrl}
                    primaryImageWidth={project.primaryImageWidth}
                    slug={project.slug}
                  />
                ))}
              </ol>
            </>
          )}
        </div>
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  margin-top: 2rem;

  .row {
    margin: 0 auto;
    max-width: 90rem;
  }

  .projects-list {
    display: grid;
    display: grid;
    gap: 4rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    list-style: none;
    margin: 0 0 4rem 0;
    padding: 0;

    > * {
      align-items: center;
      aspect-ratio: 7/5;
      display: flex;
      justify-content: center;
    }
  }

  .intro-text-wrapper {
    margin-bottom: 4rem;
    max-width: 87rem;
  }
`
