import {Link, graphql, useStaticQuery} from 'gatsby'
import Fade from 'react-reveal/Fade'
import React from 'react'
import styled from 'styled-components'

export default function ProjectThumbnail({
  name,
  location,
  isCompleted,
  primaryImageAlt,
  primaryImageHeight,
  primaryImageUrl,
  primaryImageWidth,
  slug,
}) {
  const data = useStaticQuery(graphql`
    {
      prismicSiteMeta {
        data {
          project_placeholder_image {
            url
            alt
            dimensions {
              height
              width
            }
          }
        }
      }
    }
  `)

  const prismicData = {
    placeholderImageAlt: data.prismicSiteMeta.data.project_placeholder_image.alt,
    placeholderImageHeight: data.prismicSiteMeta.data.project_placeholder_image.dimensions?.height,
    placeholderImageUrl: data.prismicSiteMeta.data.project_placeholder_image.url,
    placeholderImageWidth: data.prismicSiteMeta.data.project_placeholder_image.dimensions?.width,
  }

  return (
    <StyledComponent className="project-thumbnail">
      <Fade>
        <div className="project-thumbnail-item">
          <Link to={`/project/${slug}`} className={primaryImageUrl ? 'link primary-image' : 'link placeholder-image'}>
            <img
              className={primaryImageUrl ? 'primary-image' : 'placeholder-image'}
              src={primaryImageUrl || prismicData.placeholderImageUrl}
              alt={primaryImageAlt || prismicData.placeholderImageAlt || `${name} photo`}
              width={primaryImageWidth || prismicData.placeholderImageWidth}
              height={primaryImageHeight || prismicData.placeholderImageHeight}
            />
          </Link>
          {(name || location) && (
            <div className="info">
              {name && <h3 className="name">{name}</h3>}
              {location ? <p className="location">({location})</p> : <p className="location">&nbsp;</p>}
            </div>
          )}
        </div>
      </Fade>
    </StyledComponent>
  )
}

const StyledComponent = styled.li`
  background-color: #eeeeee;
  border: 1rem solid white;
  overflow: hidden;
  position: relative;

  .project-thumbnail-item {
    position: relative;
  }

  .link {
    display: block;
    overflow: hidden;

    &:hover {
      img {
        transform: scale(1.05);
      }
    }

    &.primary-image {
    }

    &.placeholder-image {
      border: 1px solid #e9e9e9;
    }
  }

  img {
    transform: scale(1);
    transition: transform 0.2s cubic-bezier(0.65, 0, 0.35, 1);
  }

  .info {
    background: hsl(0deg 0% 0% / 63.14%);
    bottom: 0;
    color: white;
    height: auto;
    left: 0;
    min-height: 53px;
    padding: 1rem;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 100%;

    .name {
      color: white;
      font-family: 'Montserrat', sans-serif;
      font-size: 1.05rem;
      font-weight: normal;
      margin: 0 0 0.5rem 0;
    }

    .location {
      font-family: 'Montserrat', sans-serif;
      font-size: 0.8rem;
      font-weight: normal;
    }
  }
`
